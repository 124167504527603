import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}
const AnalyticalTools = ({ match }: iProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={`${match.url}/allocation_map`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_AllocationMap')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/fund_data_list`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_FundDataList')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/fund_survey`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_FundSurvey')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/mtd_performance_report`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_MTDPerformanceReport/index'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/monthly_fund_performance`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_MonthlyFundPerfomance/index'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/index_return`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_HistoricalDataDownload/IndexReturnData'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/portfolio_statistics`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AnalyticalTool/AT_HistoricalDataDownload/PortfolioStatisData'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/strategy_allocation_contributions`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_StrategyAllocationWar')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/war_report`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_WARReport')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/allocation_ytdReturn`}
      component={asyncComponent(
        () =>
          import('../../components/AnalyticalTool/AT_FundAllocation&YTDReturn')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/hfr_strategy_report`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_HFRStrategyReport')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/fofs_summary_report`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_FofsSummaryReport')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/monitoring_list`}
      component={asyncComponent(
        () => import('../../components/AnalyticalTool/AT_MonitoringList')
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/monthly_allocation_changes`}
      component={asyncComponent(
        () =>
          import('../../components/AnalyticalTool/AT_MonthlyAllocationChanges')
      )}
    ></Route>
  </Switch>
)
export default AnalyticalTools
